@import "./../basedata/variables"

.local-loader

    position: relative
    width: 100%
    min-height: 400px
    text-align: center
    padding-top: 20%

    i
        font-size: 60px
        color: lighten($blue, 20%)
