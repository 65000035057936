@import "~bootstrap/scss/bootstrap"
@import "./../basedata/variables"

.header-line
  width: 100%
  padding: 16px
  display: flex
  justify-content: space-between

  ul
    display: flex
    justify-content: space-evenly

    li
      display: flex
      align-items: center
      margin-left: 16px
      color: $gray-600

      .item-content
        font-size: 12px
        text-transform: uppercase
        color: $gray-800
        margin-left: 16px

      .sidebar-icon
        display: inline-block
        width: 40px
        height: 40px
        padding-top: 6px
        text-align: center
        border-radius: 100%
        overflow: hidden
        background: $gray-300

        svg
          width: 50%
          height: auto
          fill: $gray-800

    span
      cursor: pointer
      &:hover
        opacity: 0.7

  .client-logo
    img
      max-width: 100px
      height: auto

      