@import "~bootstrap/scss/bootstrap"
@import "./basedata/variables"

*
    font-family: "Open Sans", sans-serif
    color: $gray-400
    font-weight: 400

h6
    font-weight: 300
    color: $gray-700

.login-logo
    max-width: 140px

body
  border: 8px solid $white
  border-radius: 16px
  background-color: $gray-200 !important

.biometry-status
    font-size: 12px
    border: 1px solid $gray-200
    border-radius: 8px
    padding: 6px
    display: inline-block

a.label
    text-decoration: none

    &:hover
        background: $pm-dark

.text-coordinator
    font-size: 12px

.link-coordinator
    text-decoration: none
    text-transform: uppercase
    font-size: 10px

.unread td
    border-bottom-width: 0 !important

.tag-item
    display: inline
    margin: 0 8px 8px 0

.biometric-progress
    width: 200px

.display-table
    margin-bottom: 0 !important
    padding-bottom: 0 !important

.card-access
    .text-numbers
        display: block
        font-size: 110px

.toast-me
    .toast-body
        color: $white

.pikerpop
    position: absolute
    z-index: 2
    min-height: 100vh
    min-width: 100vw
    left: 0
    top: 0

.chrome-picker
    z-index: 3
    position: relative

.pickerColor
    width: 60px
    height: 25px
    border: 1px solid $gray-200
    padding: 2px
    border-radius: 4px

.copyright-botton
  background-color: $pm-primary
  padding: 6px 0
  text-align: center
  font-size: 12px
  color: $white
  margin-top: -30px
  a
    color: $white
    text-decoration: none

.function-list
    text-align: center
    font-size: 10px
    text-transform: uppercase
    color: $gray-700
    padding: 8px 16px

    span
        color: $cyan-600
        display: block

.rounded-md
  border-radius: 16px

.rounded-sm
  border-radius: 8px


.auth-wrapper
  background: url('./images/login_background.jpg') left top no-repeat
  background-size: cover
  margin-bottom: -16px


.group-list
  list-style: none

  .group-item
    margin-bottom: 16px
    padding-bottom: 16px
    border-bottom: 1px solid $gray-300

.group-detail
  display: flex
  justify-content: space-between

.coordinator-list,
.group-action
  padding-left: 0

.group-action
  display: flex
  justify-content: space-evenly

  li
    display: flex
    align-items: center
    margin-left: 16px

    .item-content
      font-size: 12px
      text-transform: uppercase
      color: $gray-800
      margin-left: 16px

    .sidebar-icon
      display: inline-block
      width: 40px
      height: 40px
      padding-top: 6px
      text-align: center
      border-radius: 100%
      overflow: hidden
      background: $gray-300

      &.no-icon
        width: 130px
        border-radius: 20px
        font-size: 13px
        padding-top: 10px
        color: $gray-700

      &.embed-icon
        width: 110px
        border-radius: 20px
        font-size: 10px
        padding-top: 8px
        height: 35px
        color: $gray-700

        svg
          width: 20px
          fill: $gray-800
          color: $gray-800
          vertical-align: top

      svg
        width: 50%
        height: auto
        fill: $gray-800

      &.icon-red
        svg
          color: $red-500
          fill: $red-500

.cordinator-row
  display: flex
  justify-content: flex-start
  align-items: baseline
  margin: 8px 0
  p
    font-size: 17px
    padding-left: 16px
    color: $gray-600


.single-form-creation
  display: flex
  flex-direction: column
  
  h3
    font-size: 18px


  .table-action-search
    margin-bottom: 8px


.group-detail-list
  list-style: none
  margin: 0
  padding-left: 0

  .small-numbers-content
    justify-content: space-between
    padding-right: 10%
    
  .small-numbers .event-ticket-button .event-icon
    width: 80px
    border-radius: 8px

  .chart-title
    width: 35%
    font-size: 18px
.pagination
    display: flex
    justify-content: center



/* Container for the entire timeline */
.timeline-container
  display: flex
  justify-content: center
  padding: 40px 0
  background: #f0f4f8 /* Light background */
  border-radius: 15px
  margin: 20px


/* The horizontal timeline itself */
.timeline
  display: flex
  align-items: center
  justify-content: space-around
  position: relative
  width: 90%


/* Individual timeline item */
.timeline-item
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  width: 180px


/* Connecting line between items */
.timeline::before
  content: ''
  position: absolute
  top: 20%
  left: 0
  right: 0
  height: 4px
  background: linear-gradient(145deg, #9fb8ad, #1fc8db, #2cb5e8) /* Line gradient */
  z-index: 1


/* Circular badge for each item */
.timeline-badge
  width: 50px
  height: 50px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  color: #fff
  font-size: 1.2rem
  z-index: 2
  position: relative
  margin-bottom: 15px

  &:active
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) /* Shadow for depth */


/* Positioning and styling for the timestamp */
.timeline-timestamp
  position: absolute
  bottom: -25px
  font-size: 0.8rem
  color: #444
  background: #fff
  padding: 2px 5px
  border-radius: 5px
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)


/* Content box for each item */
.timeline-content
  // background: #fff
  padding: 15px
  border-radius: 10px
  text-align: center
  width: 150px
  height: 70px
  // box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) /* Shadow for depth */
  position: relative
  z-index: 2


/* Content title styling */
.timeline-content h5
  margin: 0
  font-size: 0.8rem
  color: #333
  font-weight: bold

.ip-boxes-container
  display: flex
  align-items: center
  justify-content: center

.ip-box
  width: 100px
  height: 50px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin: 10px
  border-radius: 8px
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
  color: #333
  font-weight: bold
  font-size: 0.9rem
  text-align: center
  transition: transform 0.3s ease

  &.ip-label
    width: 200px
    label
      color: $gray-700


.ip-box label
  font-size: 12px
  text-transform: uppercase
  font-weight: bold
  color: $gray-500


.ip-box 

  p
    font-size: 1.2em
    margin: 0
    color: $white
    
  &.loading
    p
      color: $gray-700

.ip-box:hover
  transform: scale(1.05)
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2)

