@import "~bootstrap/scss/bootstrap"
@import "../../basedata/variables"

.card
  border-color: $pm-primary !important

.card .card-header
  border-color: $pm-primary !important

.card .card-header h5:after
  background-color: $gray-500
