@import "~bootstrap/scss/bootstrap"
@import "./../basedata/variables"


.custom-navBar
  display: flex
  height: 100vh
  overflow: scroll initial
  position: fixed
  z-index: 2
  padding: 16px

  // Gambi
  .cQiZws
    height: 90% !important

  .pro-sidebar
    background-color: transparent !important

  .sidebar-content
    padding-left: 16px

    li
      display: flex
      align-items: center
      margin-bottom: 16px

      .item-content
        font-size: 12px
        text-transform: uppercase
        color: $gray-800
        margin-left: 16px

      .sidebar-icon
        display: inline-block
        width: 40px
        height: 40px
        padding-top: 6px
        text-align: center
        border-radius: 100%
        overflow: hidden
        background: $gray-300

        svg
          width: 50%
          height: auto
          fill: $gray-800

.bepass-logo
  // text-align: center
  width: 100%
  padding-left: 16px
  padding-bottom: 36px

  img
    width: 140px
