@import "~bootstrap/scss/bootstrap"
@import "./../../basedata/variables"



.main-event

  .event-header
    display: flex
    justify-content: space-between
    width: 100%
    height: 370px
    padding: 150px 56px
    background: url('./../../assets/images/bg-teste.jpg')
    border-radius: 16px
    background-position: center
    background-size: cover

  h1
    color: $white
    font-size: 3rem 
    margin-bottom: 0
    line-height: 20px

  h2
    color: $white
    font-size: 6rem 

  .event-subtitle
    font-size: 3em
    color: $gray-800
    margin-left: 16px
    margin-bottom: 32px

  .event-header-button
    width: 120px
    height: 120px
    background-color: $white
    border-radius: 100%
    border: 8px solid $gray-200
    padding-top: 42px
    font-size: 14px
    color: $black
    text-align: center
    text-decoration: none
    cursor: pointer

    &:hover
      opacity: 0.9

.event-content
  display: flex
  // justify-content: space-between
  margin-bottom: 60px

.event-header-content-box
  background-color: $gray-200
  border-radius: 16px
  padding: 16px
  margin-top: -70px
  margin-left: 56px
  text-align: center
  color: $black

  .event-ticket-button
    width: 90%
    margin: -30px auto 0 auto

  &.event-biometry-total
    position: relative
    padding-left: 8px

    .event-header-content-box-main
      margin-left: 8px
      min-width: 180px
    
    .biometry-firulita
      width: 48px
      height: 48px
      position: absolute
      top: 31%
      left: 44%
      border: 8px solid $gray-200

    .event-ticket-button
      width: 170px

.event-header-content-box-main
  display: inline-block
  background-color: $white
  border-radius: 8px
  padding: 16px
  padding-bottom: 40px
  color: $black

  .event-ticket-counter
    font-size: 3rem
    color: $gray-700
    font-size: 64px
    line-height: 60px
    margin-top: 16px

.event-ticket-button
  display: flex
  position: relative
  z-index: 1
  justify-content: space-around
  align-items: center
  font-size: 14px
  margin-top: 16px
  background-color: $white
  border: 8px solid $gray-200
  border-radius: 16px
  padding: 4px 0px
  color: $pm-primary
  text-decoration: none
  cursor: pointer

  &:hover
    opacity: 0.9



.event-icon
  display: inline-block
  width: 32px
  height: 32px
  padding-top: 4px
  text-align: center
  border-radius: 100%
  overflow: hidden
  background: $gray-300

  svg
    width: 50%
    height: auto
    fill: $gray-800



.small-numbers
  min-width: 350px
  padding: 8px 4px
  position: relative
  border-radius: 8px

  .small-numbers-content
    font-size: 18px
    line-height: 24px
    color: $gray-700
    display: flex
    align-items: center
    background-color: $white
    text-transform: uppercase
    border-radius: 8px
    padding-right: 40px

  .small-chart
    width: 110px
    padding: 16px
    position: relative

    img
        height: 80px
        width: auto 

    .chart-values
      font-size: 16px
      font-weight: 700
      color: $blue-500
      position: absolute
      top: 38%
      left: 37%

    .chart-subtitle
      font-size: 10px
      text-align: center
      color: $gray-400

  .small-description
    width: 100%
    margin-left: 16px
    font-size: 14px
    color: $gray-600



  span
    font-size: 12px
    color: $gray-400
    display: block

  .numbers-value
    color: $gray-900
    font-weight: 400
    font-size: 22px
    margin-top: 8px

    .numbers-value-off
      display: inline
      font-size: 22px
      color: $gray-400

  .event-ticket-button
    position: absolute
    bottom: -8px
    right: -8px
    border: 0
    background-color: transparent

    .event-icon
      width: 50px
      height: 50px
      padding-top: 6px
      font-size: 10px
      font-weight: bold
      color: $gray-800
      background-color: $white
      border: 8px solid $gray-200
      

.small-refference-numbers
  display: flex
  justify-content: space-between
  text-align: center


.table-action-bar
  display: flex
  justify-content: space-between
  padding: 16px
  background-color: $white
  border-radius: 8px

.button-dropdown
  background-color: $black
  border: 0
  border-radius: 8px
  padding: 8px 16px

  svg
    margin-left: 8px
